<template>
  <image-to-text-results-base-page :allow-library="true" @select-from-library="openSelectFromLibraryModal">
    <template v-slot:page-not-found>
      <page-not-found></page-not-found>
    </template>
  </image-to-text-results-base-page>
</template>

<script>
import ImageToTextResultsBasePage from '@common/pages/imageToText/ImageToTextResultsBasePage';

import SelectFromLibraryModal from '@/components/modules/imageToText/SelectFromLibraryModal';
import PageNotFound from '@/components/page.not.found.vue';

export default {
  methods: {
    openSelectFromLibraryModal() {
      let modalParams = {
        classes: 'clear_modal white_modal',
        name: 'select-from-library',
        height: 'auto',
        scrollable: true,
      };
      const props = {};
      this.$modal.show(SelectFromLibraryModal, props, modalParams);
    },
  },
  components: {PageNotFound, ImageToTextResultsBasePage},
  name: 'ImageToTextResultsPage',
};
</script>

<style scoped lang="scss"></style>
