<template>
  <div class="mcr-select-options input-style">
    <div
      class="option"
      v-for="option in options"
      :key="option.key"
      :class="{selected: option.key === selectedKey}"
      @click="$emit('option-click', option)"
      @mousedown="$emit('option-mousedown', option)"
    >
      {{ option.value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    selectedKey: [String, Number],
  },
  name: 'mcrSelectOptions',
};
</script>

<style lang="scss" scoped>
.mcr-select-options {
  border: 1px solid $input-border-color;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  padding: 0;
  .option {
    padding: 9px 12px;
    cursor: pointer;
    &:hover {
      color: $link-color;
    }
  }
  .option.selected {
    font-weight: bold;
  }
  /* .option:first-child {
      color: $supplemental-text-color;
      font-weight: normal;
    } */
}
</style>
