<template>
  <div class="library-upload-page">
    <div class="limited_content">
      <div :class="{invisible: !canLeave}">
        <router-link :to="toLibrary">‹ Go back to library</router-link>
      </div>
      <div class="page-title">
        <div class="tree-selector" :class="{invisible: !canLeave || familyTreeEditableListState.length === 0}">
          <family-tree-selector
            :list="familyTreeEditableListState"
            :tree-id="libraryFamilyTreeIdState"
            :tree-name="libraryFamilyTreeName || 'Unknown'"
            :display-inline="true"
            @select="onTreeSelect"
          >
          </family-tree-selector>
        </div>
        <span class="big-title"><upload-icon :size="24"></upload-icon>Upload to Library</span>
      </div>

      <div
        class="content"
        :class="{isDragOver: isDragOver}"
        @drop.prevent="onDrop"
        @dragenter.prevent="setIsDragOver(true)"
        @dragleave.prevent="setIsDragOver(false)"
        @drop.capture.prevent="setIsDragOver(false)"
        @dragover.capture.prevent="setIsDragOver(true)"
      >
        <template v-if="familyTreeEditableListState.length">
          <div class="upload-container">
            <div class="text-lg text-info">
              <information :size="32"></information>
              <div key="not-owner" v-if="!libraryFamilyTreeName">You can't upload to this library</div>
              <div v-if="isCompleted" key="done">Upload completed, you can close this page.</div>
              <div v-else-if="isUploading" key="doing">
                Uploading... Do not close this page.<br />
                <span class="countdown">{{ timeLeftText }}</span>
              </div>
              <div v-else-if="libraryFamilyTreeName" key="waiting">
                <template v-if="toProcessFiles.length">
                  Upload <span class="filename">{{ filesCountText }} </span> to {{ libraryFamilyTreeName }} library
                </template>
                <template v-else>
                  Select files to upload
                  <span v-if="!hasUnlimitedSize"
                    >up to {{ maxFileSizeMB }}MB or
                    <subscription-plans-link :is-button="false"></subscription-plans-link> to upload larger files.</span
                  >
                </template>
              </div>
            </div>
          </div>
          <div class="drag-and-drop" v-if="!isUploading">or drag and drop files here</div>
          <div v-if="libraryFamilyTreeName && !startTime" class="upload-container">
            <div class="upload-controls">
              <template v-if="toProcessFiles.length">
                <mcr-button @click="startUpload">Upload</mcr-button>
              </template>
              <template>
                <mcr-button @click="openFileDialog">{{ chooseFileText }}</mcr-button>
                <input ref="fileInput" multiple type="file" class="files-input" @change="fileInputChange" />
              </template>
            </div>
          </div>

          <div v-if="totalFilesCount" class="upload-container files-list">
            <uploader-file
              v-for="(file, index) in notRemovedFiles"
              :key="file.name + index"
              :file="file"
              :index="index"
              :progress="totalProgress"
              :is-completed="file.isCompleted"
              :is-in-progress="file.isInProgress"
              @remove="removeFile(file)"
            ></uploader-file>
          </div>
        </template>
        <div v-else>
          <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {FT_LIBRARY_ROUTE_NAME, FT_LIBRARY_UPLOAD_ROUTE_NAME} from '@/router/consts';
import UploaderFile from '@common/elements/animations/uploaderFile';
import McrButton from '@common/elements/buttons/mcrButton.vue';
import network from '@common/network/network';
import Information from 'vue-material-design-icons/InformationOutline.vue';
import UploadIcon from 'vue-material-design-icons/Upload.vue';
import {mapGetters} from 'vuex';

import SubscriptionPlansLink from '@/components/common/buttons/SubscriptionPlansLink.vue';
import FamilyTreeSelector from '@/components/common/inputs/familyTreeSelector.vue';

export default {
  name: 'LibraryUploadPage',
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  data() {
    return {
      originalIcons: [],
      selectedFiles: [],
      removedFiles: [],
      completedFiles: [],
      invalidFiles: [],
      defaultMaxFileSize: 104857600,
      unlimitedMaxFileSize: 10485760000,
      chunkSize: 10485760,
      totalProgress: 0,
      isUploading: false,
      isCompleted: false,
      estimatedTimeLeft: 0,
      startTime: 0,
      timerInternal: null,
      currentChunk: 0,
      error: false,
      retries: 0,
      maxRetries: 3,
      flipIcon: false,
      isDragOver: false,
      uploadInfo: {
        uploadId: null,
        asset_id: null,
        parts_info: [],
        file_key: null,
      },
    };
  },
  watch: {
    isCompleted: function (val) {
      if (val) {
        this.setIconText('✅');
      }
    },

    $route: {
      handler: function (toRoute, fromRoute) {
        const sameRoute = toRoute.name === fromRoute.name;
        const changedFtId = parseInt(toRoute.params.id) !== parseInt(fromRoute.params.id);
        if (sameRoute && changedFtId) {
          this.$store.commit('setLibraryFamilyTreeIdState', parseInt(toRoute.params.id));
        }
      },
    },
  },
  methods: {
    setIsDragOver(bool) {
      if (!this.isEditAllowed && !this.isUploading) {
        return;
      }
      this.isDragOver = bool;
    },
    onDrop(event) {
      this.isDragOver = false;
      let fileList = event.dataTransfer.files;
      if (fileList.length > 0) {
        this.filesSelected(fileList);
      }
    },
    filesSelected(files) {
      this.selectedFiles = [...this.toProcessFiles, ...files];
      this.removedFiles = [];
      this.completedFiles = [];
      this.invalidFiles = [];
      for (let file of this.selectedFiles) {
        if (this.runFilesValidation(file)) {
          file.isInvalid = true;
          this.invalidFiles.push(file);
        }
      }
    },
    fileInputChange(event) {
      this.filesSelected(event.target.files);
    },
    runFilesValidation(file) {
      if (file.size > this.maxFileSize) {
        this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB`);
        return true;
      }
    },
    onTreeSelect(value) {
      this.$router.push({name: FT_LIBRARY_UPLOAD_ROUTE_NAME, params: {id: value.object_id}});
    },
    handleFilesSelectError(errorText) {
      this.$toasted.error(errorText);
    },
    setFileCompleted(file) {
      file.isCompleted = true;
      file.isInProgress = false;
      this.completedFiles.push(file);
      this.startTime = 0;
      this.totalProgress = 0;

      if (this.toProcessFiles.length === 0) {
        this.setCompleted();
      }
    },
    setCompleted: function () {
      this.isCompleted = true;
      this.isUploading = false;
      this.startTime = 0;
      if (this.timerInternal) {
        clearInterval(this.timerInternal);
      }

      if (document.hidden) {
        this.$store.commit('addNotificationState', {
          key: this.uploadInfo.asset_id,
          content: 'File uploaded',
          action_message: 'Go to library',
          action_url: this.toLibrary,
        });
      }
    },
    setError: function (file) {
      this.$toasted.error('Error uploading file');
      this.startTime = 0;
      file.isFailed = true;
      this.invalidFiles.push(file);
      this.error = true;
      if (this.timerInternal) {
        clearInterval(this.timerInternal);
      }
    },
    getFormData: function (chunk, file, i, chunksCount, uploadId, asset_id, file_key, parts_info) {
      return {
        id: this.libraryFamilyTreeIdState,
        file_part: chunk,
        file_name: file.name,
        part_number: i + 1,
        is_final: i === chunksCount - 1,
        upload_id: uploadId,
        asset_id: asset_id,
        file_key: file_key,
        parts_info: JSON.stringify(parts_info),
        total_size: file.size,
      };
    },
    updateTimers: function (i, chunksCount) {
      this.currentChunk = i;
      this.setIconText('⏳', this.flipIcon);
      this.flipIcon = !this.flipIcon;

      if (i === 0) {
        this.startTime = new Date().getTime();

        // calculate time based on totalProgress

        this.timerInternal = setInterval(() => {
          if (this.totalProgress > 0) {
            this.estimatedTimeLeft = Math.round(
              (((new Date().getTime() - this.startTime) / this.totalProgress) * (100 - this.totalProgress)) / 1000
            );
          }
        }, 1000);
      } else {
        this.estimatedTimeLeft = Math.round((((new Date().getTime() - this.startTime) / i) * (chunksCount - i)) / 1000);
        if (this.timerInternal) {
          clearInterval(this.timerInternal);
        }
        this.timerInternal = setInterval(() => {
          if (this.estimatedTimeLeft > 0) {
            this.estimatedTimeLeft = this.estimatedTimeLeft - 1;
          } else {
            clearInterval(this.timerInternal);
          }
        }, 1000);
      }
    },
    async startUpload() {
      this.isCompleted = false;
      this.isUploading = true;
      this.totalProgress = 0;

      for (let file of this.selectedFiles) {
        if (!file.isRemoved && !file.isCompleted && !file.isInvalid && !file.isFailed) {
          await this.processFile(file);
        }
      }
    },
    async processFile(file) {
      file.isInProgress = true;

      let chunksCount = Math.ceil(file.size / this.chunkSize);
      if (chunksCount === 1) {
        this.updateTimers(0, 1);

        await network.familyTreeLibrary
          .uploadFile(
            {id: this.libraryFamilyTreeIdState, attachment: file, tags_data: JSON.stringify({})},
            progressRes => {
              this.totalProgress = (progressRes.loaded / progressRes.total) * 100;
            }
          )
          .then(response => {
            this.uploadInfo.asset_id = response.object_id;
            this.setFileCompleted(file);
          })
          .catch(error => {
            this.setError(file);
          });
        return;
      }
      let oneChunkProgressContribution = 100 / chunksCount;
      this.uploadInfo.uploadId = null;
      this.uploadInfo.asset_id = null;
      this.uploadInfo.file_key = null;
      this.uploadInfo.parts_info = [];

      for (let i = 0; i < chunksCount; i++) {
        this.updateTimers(i, chunksCount);
        if (this.error) {
          return;
        }
        if (i > 0 && !this.uploadInfo.uploadId) {
          this.setError(file);
          return;
        }

        let start = i * this.chunkSize;
        let end = Math.min(file.size, start + this.chunkSize);
        let chunk = file.slice(start, end);
        const formData = this.getFormData(
          chunk,
          file,
          i,
          chunksCount,
          this.uploadInfo.uploadId,
          this.uploadInfo.asset_id,
          this.uploadInfo.file_key,
          this.uploadInfo.parts_info
        );
        await this.uploadChunk(file, formData, oneChunkProgressContribution, i);
      }
    },
    async uploadChunk(file, formData, oneChunkProgressContribution, currentChunk) {
      await network.familyTreeLibrary
        .uploadFilePart(formData, progressRes => {
          let currentChunkProgress = ((progressRes.loaded / progressRes.total) * 100).toFixed(2);
          this.totalProgress =
            currentChunk * oneChunkProgressContribution + (currentChunkProgress * oneChunkProgressContribution) / 100;
        })
        .then(response => {
          this.uploadInfo.uploadId = response.upload_id;
          this.uploadInfo.asset_id = response.asset_id;
          this.uploadInfo.file_key = response.file_key;
          this.uploadInfo.parts_info = response.parts_info;
          this.retries = 0;
          if (formData.is_final) {
            this.setFileCompleted(file);
          }
        })
        .catch(error => {
          if (this.retries < this.maxRetries) {
            this.retries++;
            console.log('retrying', this.retries);
            this.uploadChunk(formData, oneChunkProgressContribution);
          } else {
            this.setError(file);
          }
        });
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    setIconText(text, rotate180 = false) {
      if (!this.originalIcons.length) {
        const links = document.querySelectorAll('link[rel="shortcut icon"]');
        links.forEach(e => this.originalIcons.push(e));
      }

      const canvas = document.createElement('canvas');
      canvas.height = 64;
      canvas.width = 64;
      const ctx = canvas.getContext('2d');
      if (rotate180) {
        ctx.translate(32, 32);
        ctx.rotate(Math.PI);
        ctx.translate(-32, -32);
      }
      ctx.font = '60px serif';
      ctx.fillText(text, 0, 55);

      const link = document.createElement('link');
      const oldLinks = document.querySelectorAll('link[rel="shortcut icon"]');
      oldLinks.forEach(e => e.parentNode.removeChild(e));
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      link.href = canvas.toDataURL();
      document.head.appendChild(link);
    },
    removeFile(file) {
      file.isRemoved = true;
      this.removedFiles.push(file);

      if (this.toProcessFiles.length === 0) {
        this.selectedFiles = [];
        this.removedFiles = [];
        this.completedFiles = [];
        this.invalidFiles = [];
      }
    },
  },
  computed: {
    ...mapGetters([
      'libraryFamilyTreeIdState',
      'userIsSubscribedState',
      'userIsStaffState',
      'familyTreeEditableListState',
      'libraryFamilyTreeIdState',
      'familyTreeListState',
    ]),
    isEditAllowed() {
      return !!this.libraryFamilyTreeName;
    },
    pageTitle() {
      if (this.isUploading) {
        return 'Uploading';
      }
      if (this.isCompleted) {
        return 'Upload completed';
      }
    },
    totalFilesCount() {
      return this.notRemovedFiles.length;
    },
    filesCountText() {
      if (this.toProcessFiles.length === 1) {
        return '1 file';
      }
      return this.toProcessFiles.length + ' files';
    },
    toProcessFiles() {
      return this.selectedFiles.filter(
        file =>
          !this.removedFiles.includes(file) && !this.completedFiles.includes(file) && !this.invalidFiles.includes(file)
      );
    },
    notRemovedFiles() {
      // filter out files from removedFiles list
      return this.selectedFiles.filter(file => !this.removedFiles.includes(file));
    },
    timeLeftText() {
      if (this.estimatedTimeLeft) {
        if (this.estimatedTimeLeft > 60) {
          let minutes = Math.round(this.estimatedTimeLeft / 60);
          let text = minutes === 1 ? 'minute' : 'minutes';
          return `About ${minutes} ${text} left`;
        }
        return `About ${this.estimatedTimeLeft} seconds left`;
      }
      if (this.currentChunk === 0 && this.estimatedTimeLeft === 0 && this.totalProgress < 100) {
        return 'Calculating time...';
      }
      return 'Almost there...';
    },
    libraryFamilyTreeName() {
      const tree = this.familyTreeEditableListState.find(e => e.object_id === this.libraryFamilyTreeIdState);
      return tree ? tree.name : '';
    },
    toLibrary() {
      return {name: FT_LIBRARY_ROUTE_NAME, params: {id: this.libraryFamilyTreeIdState}};
    },
    maxFileSize() {
      return this.hasUnlimitedSize ? this.unlimitedMaxFileSize : this.defaultMaxFileSize;
    },
    maxFileSizeMB() {
      return this.maxFileSize / 1048576;
    },
    hasUnlimitedSize() {
      return this.userIsSubscribedState || this.userIsStaffState;
    },
    canLeave() {
      if (this.familyTreeListState.length === 0) {
        return true;
      }
      return this.familyTreeListState.length > 0 && (this.isCompleted || this.startTime === 0);
    },
    chooseFileText() {
      return 'Add file';
    },
  },
  created() {
    this.$store.commit('setLibraryFamilyTreeIdState', parseInt(this.$route.params.id));
    this.$store.dispatch('fetchFamilyTreeListAction');
    window.onbeforeunload = () => {
      if (this.isUploading) {
        return 'Are you sure you want to leave?';
      }
    };
  },
  beforeDestroy() {
    if (this.timerInternal) {
      clearInterval(this.timerInternal);
    }
    if (this.originalIcons.length) {
      const oldLinks = document.querySelectorAll('link[rel="shortcut icon"]');
      oldLinks.forEach(e => e.parentNode.removeChild(e));
      this.originalIcons.forEach(e => document.head.appendChild(e));
    }
  },
  destroyed() {
    window.onbeforeunload = () => {};
  },
  components: {
    UploaderFile,
    FamilyTreeSelector,
    SubscriptionPlansLink,
    McrButton,
    UploadIcon,
    Information,
  },
};
</script>

<style scoped lang="scss">
.page-title {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    align-items: flex-start;
  }

  .big-title {
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
}

.tree-selector::v-deep {
  margin-top: 10px;

  .family-tree-multiselect {
    max-width: fit-content;
    width: fit-content;

    &.multiselect--active {
      max-width: 380px;
      width: 380px;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      max-width: 100%;
      &.multiselect--active {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.content {
  min-height: 162px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 48px 24px;
  background-color: $background-light;
  border-radius: 4px;

  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      display: none;
    }

    .filename {
      font-weight: 600;
    }

    .upload-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 16px;
      margin-top: 16px;
    }

    .text-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      font-size: 24px;
      row-gap: 12px;

      .info-bit {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .files-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid $divider-line-light;
    margin-top: 48px;
  }

  &.isDragOver {
    border: 2px dashed $link-color;
    cursor: copy;
  }
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.drag-and-drop {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $mcr-grey;
}

.uploading-info {
  margin-bottom: 10px;
}
</style>
