<template>
  <div class="library-assets-container" :class="containerClasses" @click="assetsContainerClick">
    <drag-drop-prompt @drop="onDrop"></drag-drop-prompt>
    <div :class="{'image-preview-gallery': previewAssets.length}">
      <file-preview
        v-for="(asset, i) in previewAssets"
        :tabindex="i"
        :title="getFilePreviewTitle(asset)"
        :key="asset._id || asset.object_id"
        :src="asset.previewSrc"
        :filename="asset.filename"
        :has-error="asset.error"
        :is-uploading="asset.uploading"
        :upload-progress="asset.progress"
        @click.native="goToDetails(asset)"
        @click.ctrl.native="openDetailsInNewPage(asset)"
        @click.meta.native="openDetailsInNewPage(asset)"
        @click.middle.native="openDetailsInNewPage(asset)"
        @mouseenter.native="setHoveredAsset(asset)"
        @mouseleave.native="setHoveredAsset(null)"
      >
        <copy-button v-if="renderCopyButton" :copyText="asset.object_id" :ref="getCopyButtonRef(asset)"> </copy-button>
        <div class="staff-description" v-if="userIsStaffState && asset.description">
          {{ asset.description }}
        </div>
      </file-preview>

      <div v-if="!familyTreeLibraryAssetsState.length" class="empty-results">
        <div class="no-files-found-notice" v-if="filtersApplied">
          <p>No assets found for the selected filters.</p>
          <p><a @click="clearSearch">Clear search</a></p>
        </div>
        <template v-else>
          <div class="empty-state">
            <upload-icon :size="56" class="upload-icon"></upload-icon>
            <div class="label" v-if="isDesktop">Drag photos, videos, and files here to upload</div>
            <div class="label" v-else>Tap to upload photos, videos and files</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DragDropPrompt from '@common/elements/layouts/DragDropPrompt';
import FilePreview from '@common/elements/layouts/file-preview/filePreview';
import isEmpty from 'lodash/isEmpty';
import UploadIcon from 'vue-material-design-icons/Upload';
import {mapGetters} from 'vuex';

import copyButton from '@/base/elements/buttons/copyButton.vue';

export default {
  data() {
    return {
      hoveredAsset: null,
      backTicKeyCode: 192, // ` key
    };
  },
  created() {
    if (this.renderCopyButton) {
      document.addEventListener('keydown', this.onKeyDown);
    }
  },
  destroyed() {
    if (this.renderCopyButton) {
      document.removeEventListener('keydown', this.onKeyDown);
    }
  },
  computed: {
    ...mapGetters([
      'familyTreeLibraryAssetsState',
      'familyTreeLibraryActiveFiltersState',
      'familyTreeLibraryAssetsMetaState',
      'userIsStaffState',
    ]),
    filtersApplied() {
      return !isEmpty(this.familyTreeLibraryActiveFiltersState);
    },
    containerClasses() {
      return {
        'is-empty': !this.familyTreeLibraryAssetsState.length,
      };
    },
    previewAssets() {
      return this.familyTreeLibraryAssetsState.map(asset => {
        return {
          ...asset,
          previewSrc: this.getPreviewSrc(asset),
        };
      });
    },
    renderCopyButton() {
      return this.userIsStaffState;
    },
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.mainMenu;
    },
  },
  methods: {
    onDrop(files) {
      if (files.length > 0) {
        this.$emit('drop-files', files);
      }
    },
    getPreviewSrc(asset) {
      if (asset.uploadingPreviewSrc) {
        return asset.uploadingPreviewSrc;
      }
      return asset.attachment;
    },
    clearSearch() {
      this.$emit('clear-filters');
    },
    goToDetails(asset) {
      if (asset.object_id) {
        const page = this.familyTreeLibraryAssetsMetaState.offset / this.familyTreeLibraryAssetsMetaState.limit + 1;
        this.$store.commit('setAssetsDetailsChunkPageNumberState', page);
        const idsList = this.familyTreeLibraryAssetsState.map(asset => asset.object_id).filter(id => Boolean(id));
        this.$store.commit('setAssetsDetailsIdsListState', idsList);
        this.$router.push({
          name: 'familytree-library-asset-details',
          params: {assetId: asset.object_id},
        });
      }
    },
    openDetailsInNewPage(asset) {
      if (asset.object_id) {
        let route = this.$router.resolve({
          name: 'familytree-library-asset-details',
          params: {assetId: asset.object_id},
        });
        window.open(route.href, '_blank');
      }
    },
    onKeyDown(e) {
      if (e.which === this.backTicKeyCode && this.hoveredAsset && this.hoveredAsset.object_id) {
        const ref = this.getCopyButtonRef(this.hoveredAsset);
        this.$refs[ref][0].clipBoardCopy();
      }
    },
    setHoveredAsset(asset) {
      this.hoveredAsset = asset;
    },
    getCopyButtonRef(asset) {
      return `copy-button-${asset._id || asset.object_id}`;
    },
    getFilePreviewTitle(asset) {
      if (this.userIsStaffState) {
        return asset.description;
      }
    },
    assetsContainerClick() {
      if (!this.isDesktop && !this.familyTreeLibraryAssetsState.length && !this.filtersApplied) {
        this.addFiles();
      }
    },
    addFiles() {
      this.$emit('add-files');
    },
  },
  components: {FilePreview, copyButton, DragDropPrompt, UploadIcon},
};
</script>

<style lang="scss" scoped>
.library-assets-container {
  border-radius: 4px;
  border: 1px dashed transparent;
  transition: all 0.2s ease;
  position: relative;
  min-height: 50vh;
  display: flex;

  &.is-empty {
    margin-top: 24px;
    border-color: rgba($border-color, 0.7);
    align-items: center;
    justify-content: center;
  }

  .library-asset-preview {
    margin: 20px;
  }

  .image-preview-gallery {
    height: 100%;
  }

  .drag-prompt {
    justify-content: flex-start;
    padding-top: 100px;
    z-index: 3;
  }

  .empty-results {
    color: $mcr-light-grey;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all 0.2s ease;

    > * {
      text-align: center;
    }

    .empty-state {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      align-items: center;
      justify-content: center;

      .label {
        color: $neutral-600;
      }

      .upload-icon {
        color: $neutral-400;
      }
    }
  }

  .file-preview {
    box-shadow: $box-shadow-light;
    background-color: $background-alternate-color;
    align-self: flex-start;
    .staff-description {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 6px 12px;
      background-color: rgba(black, 0.75);
      color: white;
      z-index: 2;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .copy-button {
    z-index: 1;
  }
}
</style>
