<template>
  <div class="copy-button">
    <button type="button" @click.stop="clipBoardCopy"><copy-icon :size="19" /><span>Copy ID</span></button>
    <transition name="fade">
      <div v-if="displayCopiedSuccess" class="copy-success">Copied!</div>
    </transition>
  </div>
</template>

<script>
import copyIcon from 'vue-material-design-icons/ClipboardText';

export default {
  props: {
    copyText: String,
  },

  data() {
    return {
      displayCopiedSuccess: false,
    };
  },
  methods: {
    clipBoardCopy() {
      this.$copyText(this.copyText).then(() => {
        this.copyLinkSuccess();
      });
    },
    copyLinkSuccess() {
      this.displayCopiedSuccess = true;
      setTimeout(() => {
        this.displayCopiedSuccess = false;
      }, 1000);
    },
  },
  components: {copyIcon},
};
</script>

<style lang="scss" scoped>
@import '@common/style/mcr-button';

.copy-button {
  position: absolute;
  top: 0px;
  right: 0px;
  .copy-success,
  button {
    width: 110px;
    font-size: 0.75em;
  }
  .copy-success {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 4px;
    background-color: $success-color;
    color: rgba(white, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 13px;
    box-sizing: border-box;
    z-index: 3;
  }
  button {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    .material-design-icon {
      margin-right: 4px;
      opacity: 0.9;
    }
    span {
      white-space: nowrap;
      text-transform: uppercase;
      opacity: 0.9;
    }
    &:hover {
      opacity: 1;
    }
  }
}
</style>
