<template>
  <div class="active-filter-tag">
    <slot></slot>
    <div class="remove"><span>X</span></div>
  </div>
</template>

<script>
export default {
  name: 'ActiveFilterTag',
};
</script>

<style lang="scss" scoped>
.active-filter-tag {
  padding: 5px 10px;
  padding-right: 34px;
  border-radius: 4px;
  cursor: pointer;
  background: $background-alternate-color;
  color: $text-color;
  box-shadow: $box-shadow-light;
  position: relative;

  .remove {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 33px;
    color: rgba(black, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    span {
      font-size: 0.8em;
      position: relative;
      top: 1px;
    }
  }

  &:hover {
    .remove {
      color: rgba(black, 0.7);
    }
  }
}
</style>
