<script>
import FamilyTreeLibraryAssetDetailsPage from './FamilyTreeLibraryAssetDetailsPage';
import FamilyTreeLibraryLandingPage from './FamilyTreeLibraryLandingPage';

export default {
  functional: true,
  render: function (createElement, context) {
    const component = context.parent.$store.getters.userIsLoggedInState
      ? FamilyTreeLibraryAssetDetailsPage
      : FamilyTreeLibraryLandingPage;
    return createElement(component, context.data);
  },
};
</script>
