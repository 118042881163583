<template>
  <div>
    <div class="readable_content">
      <h1>Library is missing or is private!</h1>
      <p>Sorry, this library does not exist, or you have no rights to access this library.</p>
      <div class="heading-6">You can try...</div>
      <ul>
        <li><router-link :to="toLogin">Logging in</router-link> to an account that has access to the library</li>
        <li>Check if the URL is correct</li>
        <li>Ask owner of the tree to make it public</li>
        <li v-if="$store.getters.userIsLoggedInState"><router-link :to="myLibrary">Go to your library</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    toLogin() {
      return {name: 'login', query: {redirect: this.$route.fullPath}};
    },
    myLibrary() {
      return {name: 'familytree-library', params: {id: this.$store.getters.userFamilyTreeIdState}};
    },
  },
};
</script>

<style scoped></style>
