<template>
  <image-to-text-base-page
    v-if="userIsLoggedInState"
    :allow-library="true"
    @select-from-library="openSelectFromLibraryModal"
  ></image-to-text-base-page>
  <image-to-text-landing-page v-else></image-to-text-landing-page>
</template>

<script>
import ImageToTextBasePage from '@common/pages/imageToText/ImageToTextBasePage';
import {mapGetters} from 'vuex';

import ImageToTextLandingPage from './ImageToTextLandingPage';
import SelectFromLibraryModal from './SelectFromLibraryModal';

export default {
  computed: {
    ...mapGetters(['userIsLoggedInState']),
  },
  methods: {
    openSelectFromLibraryModal() {
      let modalParams = {
        classes: 'clear_modal white_modal',
        name: 'select-from-library',
        height: 'auto',
        scrollable: true,
      };
      const props = {};
      this.$modal.show(SelectFromLibraryModal, props, modalParams);
    },
  },
  components: {ImageToTextBasePage, ImageToTextLandingPage},
  name: 'ImageToTextPage',
};
</script>

<style scoped lang="scss"></style>
