<template>
  <mcr-select
    class="light-select"
    :selected-key="familyTreeAssetsOrderingState"
    :options="options"
    @select="select"
  ></mcr-select>
</template>

<script>
import {mapGetters} from 'vuex';

import McrSelect from '@/base/elements/filters/mcrSelect';

export default {
  data() {
    return {
      options: [
        {key: '-updated_at', value: 'Last Modified'},
        {key: '-created_at', value: 'Uploaded'},
        {key: '-event_datetime', value: 'Event Date (New to Old)'},
        {key: 'event_datetime', value: 'Event Date (Old to New)'},
      ],
    };
  },
  computed: {
    ...mapGetters(['familyTreeAssetsOrderingState']),
  },
  methods: {
    select(ordering) {
      if (ordering !== this.familyTreeAssetsOrderingState) {
        this.$router.push({query: {...this.$route.query, ordering}});
        this.$store.commit('setFamilyTreeAssetsOrderingState', ordering);
        this.$emit('change');
      }
    },
  },
  components: {McrSelect},
};
</script>

<style lang="scss" scoped>
.mcr-select {
  min-width: 235px;
}
</style>
