<template>
  <div class="asset-details-container">
    <images-gallery-overlay
      ref="images-gallery-overlay"
      :src="src"
      :transitionKey="assetId"
      :has-next="hasNext"
      :has-previous="hasPrevious"
      :show-close="true"
      :imageContainerClasses="imageContainerClasses"
      :rotate-image="rotationAngle"
      :filename="assetDetails && assetDetails.filename"
      :is-mode-area-selection="isOcrSelectionMode"
      @area-selected="onAreaSelected"
      @click-close="goToLibrary"
      @click-next="navigate(true)"
      @click-previous="navigate(false)"
      @hide-image="onHideImage"
      @image-loaded="onImageLoad"
    >
      <template v-slot:absolute-actions>
        <div class="mobile-title">
          <div class="heading-6 text-md-mobile main-title" v-if="openedFromLibrary">{{ header }}</div>
          <div class="file-count text-md-mobile" v-if="openedFromLibrary">{{ filesCountLabel }}</div>
          <save-rotation-button
            v-if="rotationAngle && isEditAllowed"
            :angle="rotationAngle"
            :asset-id="assetId"
            @saved="onRotationSaved"
          ></save-rotation-button>
        </div>
      </template>
      <template v-slot:image-port-actions>
        <extract-text-prompt
          :shown="isOcrSelectionMode"
          :disabled="isOcrConfirmDisabled"
          @dismiss="toggleExtractText"
          @confirm="onExtractTextConfirm"
        ></extract-text-prompt>
      </template>
      <div class="info-bar-content" slot="info-bar">
        <div class="heading-6 main-title" v-if="openedFromLibrary">{{ header }}</div>
        <div class="file-count" v-if="openedFromLibrary">{{ filesCountLabel }}</div>

        <div class="info-bar-actions" v-if="areDetailsShown">
          <div class="icon-action" v-if="isFileImage">
            <div class="icon-container" @click="toggleExtractText" :class="{'is-active': isOcrSelectionMode}">
              <ocr-icon :size="24"></ocr-icon>
            </div>
            <div class="label text-sm">Extract Text</div>
          </div>
          <div class="rotate-picture icon-action" v-if="isFileImage">
            <div class="icon-container" @click="rotateImage">
              <rotate-icon :size="24"></rotate-icon>
            </div>
            <div class="label text-sm">Rotate</div>
          </div>

          <asset-download-button
            v-if="isDesktop"
            :href="assetDetails.attachment"
            :filename="assetDetails.filename"
          ></asset-download-button>

          <div v-if="canRemovePicture && isDesktop" class="icon-action remove-picture">
            <div class="icon-container" @click="showDeleteAssetModal">
              <delete-icon :size="24"></delete-icon>
            </div>
            <div class="label text-sm">Delete</div>
          </div>
          <div v-if="!isDesktop" class="icon-action">
            <div class="icon-container" @click="openMobileDetails">
              <pencil-icon :size="24"></pencil-icon>
            </div>
            <div class="label text-sm">{{ toggleDetailsText }}</div>
          </div>
          <div v-if="!isDesktop" class="icon-action">
            <div class="icon-container" @click="openMoreOptionsModal">
              <dots-vertical-icon :size="24"></dots-vertical-icon>
            </div>
            <div class="label text-sm">More</div>
          </div>
        </div>

        <div class="additional-actions text-sm">
          <save-rotation-button
            v-if="rotationAngle && isEditAllowed && isDesktop"
            :angle="rotationAngle"
            :asset-id="assetId"
            @saved="onRotationSaved"
          ></save-rotation-button>
        </div>

        <asset-details-block
          v-if="isDetailsBlockShown"
          :is-edit-allowed="isEditAllowed"
          :asset-details="assetDetails"
        ></asset-details-block>
      </div>
    </images-gallery-overlay>
    <modal :name="deleteModalName" classes="clear_modal white_modal" class="mobile_bottom">
      <delete-confirm-modal-content
        :modal-name="deleteModalName"
        :loading="familyTreeAssetDeleteLoadingState"
        @confirm="deleteAsset"
      >
        <h5>Are you sure you want to delete this file?</h5>
      </delete-confirm-modal-content>
    </modal>
  </div>
</template>

<script>
import ImagesGalleryOverlay from '@common/elements/gallery/imagesGalleryOverlay';
import {getNonImagePreviewSrcByFilename, isImageFile} from '@common/elements/layouts/file-preview/filePreviewHelper';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import debounce from 'lodash/debounce';
import DeleteIcon from 'vue-material-design-icons/Delete';
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical';
import OcrIcon from 'vue-material-design-icons/Ocr';
import PencilIcon from 'vue-material-design-icons/Pencil';
import RotateIcon from 'vue-material-design-icons/Reload';
import {mapGetters} from 'vuex';

import DeleteConfirmModalContent from '@/base/elements/modals/deleteConfirmModalContent';

import AssetDetailsBlock from '@/components/common/tree/AssetDetailsBlock';
import AssetDetailsBlockModal from '@/components/common/tree/AssetDetailsBlockModal';
import AssetMoreOptionsModal from '@/components/common/tree/AssetMoreOptionsModal.vue';
import ExtractTextModalContent from '@/components/common/tree/ExtractTextModalContent.vue';
import ExtractTextPrompt from '@/components/common/tree/ExtractTextPrompt';

import AssetDownloadButton from '../common/AssetDownloadButton';
import SaveRotationButton from '../common/SaveRotationButton';

export default {
  beforeMount() {
    this.saveChunkPageNumber(this.$store.getters.assetsDetailsChunkPageNumberState);
  },
  mounted() {
    this.fetchAssetDetails();
  },
  watch: {
    assetId() {
      this.areDetailsHidden = false;
      this.fetchAssetDetails();
    },
  },
  data() {
    return {
      maxChunkPageNumber: null,
      minChunkPageNumber: null,
      deleteModalName: 'delete-modal',
      areDetailsHidden: false,
      navigationNextThrottled: false,
      navigationBackThrottled: false,
      rotationAngle: 0,
      isOcrSelectionMode: false,
      isOcrConfirmDisabled: true,
    };
  },
  computed: {
    ...mapGetters([
      'assetsDetailsIdsListState',
      'familyTreeAssetDetailsByIdState',
      'familyTreeLibraryAssetsMetaState',
      'familyTreeLibraryActiveFiltersState',
      'assetsDetailsChunkPageNumberState',
      'familyTreeAssetDeleteLoadingState',
      'activeFamilyTreeIdState',
      'windowWidthState',
    ]),
    assetId() {
      return this.$route.params.assetId;
    },
    assetDetails() {
      return this.familyTreeAssetDetailsByIdState[this.assetId];
    },
    openedFromLibrary() {
      return Boolean(this.assetsDetailsIdsListState.length);
    },
    src() {
      if (this.assetDetails && this.assetDetails.attachment) {
        return getNonImagePreviewSrcByFilename(this, this.assetDetails.filename) || this.assetDetails.attachment;
      }
    },
    filesCountLabel() {
      const totalCount = this.familyTreeLibraryAssetsMetaState.total_count;
      return this.openedFromLibrary ? `${this.currentCount} of ${totalCount} files` : '';
    },
    header() {
      return this.familyTreeLibraryActiveFiltersState.length ? 'Search Results' : 'Photos & Files';
    },
    isFileImage() {
      return this.assetDetails ? isImageFile(this.assetDetails.filename) : false;
    },
    showFilename() {
      return this.assetDetails && !this.isFileImage;
    },
    hasNext() {
      return this.openedFromLibrary && this.currentCount < this.familyTreeLibraryAssetsMetaState.total_count;
    },
    hasPrevious() {
      return this.openedFromLibrary && this.currentCount > 1;
    },
    currentIndex() {
      return (
        this.assetsDetailsIdsListState.indexOf(this.assetId) +
        (this.minChunkPageNumber - 1) * this.familyTreeLibraryAssetsMetaState.limit
      );
    },
    currentCount() {
      return this.currentIndex + 1;
    },
    imageContainerClasses() {
      return {'is-image': this.isFileImage};
    },
    toggleDetailsText() {
      return this.isEditAllowed ? 'Edit Details' : 'View Details';
    },
    areDetailsShown() {
      return !this.areDetailsHidden && this.assetDetails;
    },
    isDesktop() {
      return this.windowWidthState >= this.$breakpoints.desktop;
    },
    isDetailsBlockShown() {
      return this.areDetailsShown && this.isDesktop && !this.isOcrSelectionMode;
    },
    familyTreeId() {
      return this.assetDetails ? this.assetDetails.family_tree_id : null;
    },
    isEditAllowed() {
      return this.assetDetails && (this.assetDetails.is_owner || this.assetDetails.is_editor);
    },
    canRemovePicture() {
      return this.isEditAllowed;
    },
  },
  methods: {
    fetchAssetDetails: debounce(function () {
      this.$store.dispatch('getAssetDetailsAction', {assetId: this.assetId});
    }, 500),
    goToLibrary() {
      this.$router.push({name: 'familytree-library', params: {id: this.familyTreeId || this.activeFamilyTreeIdState}});
    },
    async getNextAssetId() {
      if (
        this.assetsDetailsIdsListState.indexOf(this.assetId) === this.assetsDetailsIdsListState.length - 1 &&
        this.hasNext
      ) {
        this.navigationNextThrottled = true;
        await this.fetchAssetsList(this.maxChunkPageNumber + 1, true);
        this.navigationNextThrottled = false;
      }
      const currentIndex = this.assetsDetailsIdsListState.indexOf(this.assetId);
      const nextAssetId = this.assetsDetailsIdsListState[currentIndex + 1];
      return nextAssetId;
    },
    async getPreviousAssetId() {
      if (this.assetsDetailsIdsListState.indexOf(this.assetId) === 0 && this.hasPrevious) {
        this.navigationBackThrottled = true;
        await this.fetchAssetsList(this.minChunkPageNumber - 1, false);
        this.navigationBackThrottled = false;
      }
      return this.assetsDetailsIdsListState[this.assetsDetailsIdsListState.indexOf(this.assetId) - 1];
    },
    navigate(toNext) {
      if (toNext) {
        return this.navigationNextThrottled ? null : this.clickNext();
      }
      return this.navigationBackThrottled ? null : this.clickPrevious();
    },
    async clickNext() {
      const assetId = await this.getNextAssetId();
      this.$router.push({name: 'familytree-library-asset-details', params: {assetId}});
    },
    async clickPrevious() {
      const assetId = await this.getPreviousAssetId();
      this.$router.push({name: 'familytree-library-asset-details', params: {assetId}});
    },
    fetchAssetsList(page, isNextPge) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getFamilyTreeLibraryFilteredAssetsChunkAction', {page}).then(response => {
          const newIds = response.objects
            .map(asset => asset.object_id)
            .filter(id => !this.assetsDetailsIdsListState.includes(id));
          const newAssets = isNextPge
            ? [...this.assetsDetailsIdsListState, ...newIds]
            : [...newIds, ...this.assetsDetailsIdsListState];
          this.saveChunkPageNumber(page);
          this.$store.commit('setAssetsDetailsIdsListState', newAssets);
          resolve();
        });
      });
    },
    saveChunkPageNumber(page) {
      this.maxChunkPageNumber =
        this.maxChunkPageNumber && this.maxChunkPageNumber > page ? this.maxChunkPageNumber : page;
      this.minChunkPageNumber =
        this.minChunkPageNumber && this.minChunkPageNumber < page ? this.minChunkPageNumber : page;
    },
    showDeleteAssetModal() {
      this.$modal.show(this.deleteModalName);
    },
    deleteAsset() {
      this.$store.dispatch('deleteAssetAction', this.assetId).then(async () => {
        const assetId = this.assetId;
        this.$modal.hide(this.deleteModalName);
        let redirectId = this.hasNext
          ? await this.getNextAssetId()
          : this.hasPrevious
          ? await this.getPreviousAssetId()
          : null;

        this.$store.commit(
          'setAssetsDetailsIdsListState',
          this.assetsDetailsIdsListState.filter(id => id !== assetId)
        );
        this.$store.commit('removeFromFamilyTreeLibraryAssetsState', assetId);
        this.$store.commit('setAssetWasDeletedState', true);

        if (redirectId) {
          this.$router.push({name: 'familytree-library-asset-details', params: {assetId: redirectId}});
        } else {
          this.goToLibrary();
        }
      });
    },
    openMobileDetails() {
      const props = {
        isEditAllowed: this.isEditAllowed,
        assetId: this.assetId,
      };
      const modalParams = {
        classes: 'clear_modal dark_modal',
        class: 'mobile_bottom over-gallery-overlay',
        name: 'asset-edit',
        height: 'auto',
        scrollable: true,
      };
      this.$modal.show(AssetDetailsBlockModal, props, modalParams);
    },
    openMoreOptionsModal() {
      const props = {
        href: this.assetDetails.attachment,
        filename: this.assetDetails.filename,
        deleteHandler: this.showDeleteAssetModal,
        canDelete: this.canRemovePicture,
      };
      const modalParams = {
        classes: 'clear_modal dark_modal',
        class: 'mobile_bottom over-gallery-overlay',
        name: 'more-options',
      };
      this.$modal.show(AssetMoreOptionsModal, props, modalParams);
    },
    onHideImage() {
      this.areDetailsHidden = true;
      this.rotationAngle = 0;
    },
    rotateImage() {
      this.rotationAngle = (this.rotationAngle + 90) % 360;
    },
    onRotationSaved() {
      this.rotationAngle = 0;
    },
    onImageLoad() {
      this.$store.dispatch('preloadNextFamilyTreeLibrarySrcsAction', {displayAssetId: this.assetId});
    },
    onAreaSelected(value) {
      this.isOcrConfirmDisabled = false;
    },
    toggleExtractText() {
      if (!this.isOcrSelectionMode) {
        AnalyticsMainHandler.trackClickSelectToOcrEvent(getRoutePageName(this.$route));
      }
      if (!this.isDesktop && !this.isOcrSelectionMode) {
        const props = {src: this.assetDetails.attachment};
        this.$modal.show(ExtractTextModalContent, props, {
          height: 'auto',
          scrollable: true,
          classes: 'clear_modal white_modal',
          class: 'over-gallery-overlay',
          name: 'ocr',
        });
        return;
      }
      this.isOcrSelectionMode = !this.isOcrSelectionMode;
      this.isOcrConfirmDisabled = true;
    },
    onExtractTextConfirm(value) {
      if (this.$refs['images-gallery-overlay']) {
        this.$refs['images-gallery-overlay'].confirmOcrSelection(value);
      }
    },
  },
  components: {
    ExtractTextPrompt,
    AssetDetailsBlock,
    SaveRotationButton,
    AssetDownloadButton,
    ImagesGalleryOverlay,
    DeleteConfirmModalContent,
    DeleteIcon,
    PencilIcon,
    RotateIcon,
    OcrIcon,
    DotsVerticalIcon,
  },
  name: 'AssetDetailsContainer',
};
</script>

<style lang="scss" scoped>
@import '@common/style/gallery.info';
.images-gallery-overlay {
  z-index: calc(#{$main-menu-z-index} + 1);
}

.asset-details-container::v-deep {
  .image-port .image-container .image {
    overflow: hidden;
  }
}
</style>
