<template>
  <div class="new-image-sub-actions">
    <div class="sub-action text-md" @click="selectFromLibrary">
      <images-icon :size="20" /><span>Select from tree library</span>
    </div>
    <div class="sub-action text-md" @click="uploadFromDevice">
      <upload-icon :size="20" /><span>Upload from device</span>
    </div>
  </div>
</template>

<script>
import ImagesIcon from 'vue-material-design-icons/ImageMultiple';
import UploadIcon from 'vue-material-design-icons/Upload';

export default {
  methods: {
    selectFromLibrary() {
      this.$emit('select-from-library');
    },
    uploadFromDevice() {
      this.$emit('upload');
    },
  },
  components: {ImagesIcon, UploadIcon},
  name: 'NewImageSubActions',
};
</script>

<style scoped lang="scss">
.new-image-sub-actions {
  .sub-action {
    width: 208px;
    padding: 12px;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    color: $neutral-800;
    .material-design-icon {
      color: $neutral-600;
    }
    &:hover {
      color: $primary-600;
      background: $primary-50;
      .material-design-icon {
        color: $primary-600;
      }
    }
  }
}
</style>
