<template>
  <div class="image-to-text-landing-page">
    <div class="info_page">
      <header>
        <div class="heading-1 heading-2-mobile">Extract Chinese text from photos and scans</div>
        <div class="heading-6">
          Extract and translate records with the gold standard in Optical Character Recognition technology. Powered by
          Baidu & Google OCR.
        </div>
        <mcr-button-router-link
          :to="toRegister"
          :label="registerLabel"
          @click="trackPreRegisterAction"
        ></mcr-button-router-link>
        <img :src="$getAsset('/assets/services/imagetotext-header.png')" class="header-splash" />
      </header>
    </div>
    <mcr-footer></mcr-footer>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

import mcrFooter from '@/components/common/mcrFooter';

export default {
  data() {
    return {
      registerLabel: 'Extract Text Now',
    };
  },
  computed: {
    targetRouteString() {
      return this.$route.fullPath;
    },
    toRegister() {
      return {name: 'register', query: {redirect: this.targetRouteString}};
    },
    toLogin() {
      return {name: 'login', query: {redirect: this.targetRouteString}};
    },
  },
  methods: {
    trackPreRegisterAction() {
      AnalyticsMainHandler.trackPreRegisterActionLinkClick(getRoutePageName(this.$route), this.registerLabel);
    },
  },
  name: 'ImageToTextLandingPage',
  components: {McrButtonRouterLink, mcrFooter},
};
</script>

<style scoped lang="scss">
@import '@common/style/info.page';
.image-to-text-landing-page {
  header {
    max-width: 960px;
    margin: auto;
    .header-splash {
      margin: 55px 0 0 0;
    }

    .heading-1 {
      text-align: center;
    }

    .heading-6 {
      margin: 16px 0;
      font-weight: normal;
      color: $neutral-600;
      max-width: 620px;
      text-align: center;
    }

    .powered-by {
      color: $neutral-700;
      .bold {
        color: $neutral-600;
      }
    }

    .mcr-button {
      margin: 48px 0 15px;
    }
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    header {
      padding: 32px 8px 60px 8px;

      .header-splash {
        margin: 48px 0 0 0;
      }
    }
  }
}
</style>
