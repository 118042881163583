<template>
  <base-pagination-meta :meta="meta"></base-pagination-meta>
</template>

<script>
import BasePaginationMeta from '@common/elements/base-pagination-meta';

export default {
  components: {BasePaginationMeta},
  computed: {
    meta() {
      return this.$store.getters.familyTreeLibraryAssetsDisplayMetaState;
    },
  },
};
</script>

<style lang="scss" scoped></style>
