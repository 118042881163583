<template>
  <div class="image-to-text-page">
    <div class="limited_content">
      <div class="heading-1 heading-2-mobile">Extract Chinese text and translate your uploaded records</div>
      <stand-out-block v-if="uploadImageState && uploadImageState.uploading">
        <translate-icon class="translate-icon" :size="66"></translate-icon>
        <div class="heading-4 heading-5-mobile">Uploading... Do not close this page.</div>
        <uploader-file
          :file="uploadImageState"
          :progress="uploadImageState.progress"
          :is-completed="uploadImageState.progress >= 100"
          :is-in-progress="uploadImageState.uploading"
        ></uploader-file>
      </stand-out-block>
      <stand-out-block v-else>
        <drag-drop-prompt @drop="onDrop"></drag-drop-prompt>

        <translate-icon class="translate-icon" :size="66"></translate-icon>
        <div class="heading-4 heading-5-mobile">Upload an image for text extraction</div>
        <div class="drop-files-message text-lg">
          Drop files here or <a @click="uploadButtonClick">click to upload</a>
        </div>
        <a class="upload-link text-lg" @click="uploadButtonClick">+ Upload</a>
        <div class="library-select" v-if="allowLibrary">
          <div class="text-md or-label">or</div>
          <mcr-button class="white small" @click="selectFromLibraryClick"
            ><images-icon :size="20" /> <span>Select from tree library</span></mcr-button
          >
        </div>
      </stand-out-block>
      <image-to-text-input :ref="filesInputRef" @uploaded="onFileUploaded"></image-to-text-input>
    </div>
  </div>
</template>

<script>
import UploaderFile from '@common/elements/animations/uploaderFile';
import McrButton from '@common/elements/buttons/mcrButton';
import DragDropPrompt from '@common/elements/layouts/DragDropPrompt';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import ImagesIcon from 'vue-material-design-icons/ImageMultiple';
import TranslateIcon from 'vue-material-design-icons/TranslateVariant';
import {mapGetters} from 'vuex';

import ImageToTextInput from './ImageToTextInput';

export default {
  props: {
    allowLibrary: {type: Boolean, default: false},
  },
  data() {
    return {
      filesInputRef: 'files-input',
    };
  },
  computed: {
    ...mapGetters(['uploadImageState', 'userFamilyTreeIdState']),
  },
  methods: {
    onDrop(files) {
      this.$refs[this.filesInputRef].filesSelected(files);
    },
    onFileUploaded() {
      if (this.allowLibrary) {
        const action = {text: 'View', push: {name: 'familytree-library', params: {id: this.userFamilyTreeIdState}}};
        this.$toasted.success('Image is added to Photos & Files.', {action});
      }
    },
    uploadButtonClick() {
      this.$refs[this.filesInputRef].$refs.input.click();
    },
    selectFromLibraryClick() {
      this.$emit('select-from-library');
    },
  },
  name: 'ImageToTextBasePage',
  components: {ImageToTextInput, DragDropPrompt, UploaderFile, McrButton, StandOutBlock, TranslateIcon, ImagesIcon},
};
</script>

<style scoped lang="scss">
.image-to-text-page {
  .heading-1 {
    text-align: center;
  }

  .stand-out-block {
    margin-top: 56px;
    padding: 42px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .translate-icon {
      color: $primary-200;
    }
    .heading-4 {
      margin-top: 20px;
      font-weight: 400;
    }
    .drop-files-message {
      margin-top: 12px;
      color: $neutral-600;
      a {
        font-weight: 500;
        text-decoration: underline;
      }
    }
    .upload-link {
      display: none;
    }

    .library-select {
      width: 100%;
      padding-top: 21px;
      margin-top: 16px;
      border-top: 1px solid $neutral-200;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .or-label {
        display: none;
      }
    }

    .uploader-file {
      margin-top: 40px;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .stand-out-block {
      margin-top: 32px;
      padding: 22px 12px;

      .drop-files-message {
        display: none;
      }
      .upload-link {
        display: flex;
        margin-top: 12px;
      }
      .library-select {
        border-top: 0;
        padding-top: 0;
        margin-top: 0;
        .or-label {
          display: block;
          color: $neutral-400;
          margin-bottom: 8px;
        }
      }

      .uploader-file {
        margin-top: 24px;
      }
    }
  }
}
</style>
