<template>
  <div class="family-tree-library-asset-details-page">
    <asset-details-container></asset-details-container>
  </div>
</template>

<script>
import AssetDetailsContainer from './details/AssetDetailsContainer';

export default {
  components: {AssetDetailsContainer},
};
</script>

<style lang="scss" scoped></style>
