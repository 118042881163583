<template>
  <div class="active-filters-container">
    <active-filter-tag
      v-for="filter in familyTreeLibraryActiveFiltersState"
      :type="tagTypeByFilterType[filter.filterType]"
      :key="filter.filterValue"
      @click.native="removeActiveFilter(filter)"
    >
      {{ filter.displayName }}
    </active-filter-tag>
  </div>
</template>

<script>
import ActiveFilterTag from '@common/elements/filters/ActiveFilterTag';
import {mapGetters} from 'vuex';

import {FILTER_LOCATIONS, FILTER_PERSONS, FILTER_SEARCH} from '../consts';

export default {
  components: {ActiveFilterTag},
  data() {
    return {
      tagTypeByFilterType: {
        [FILTER_PERSONS]: 'person',
        [FILTER_LOCATIONS]: 'location',
        [FILTER_SEARCH]: 'search',
      },
    };
  },
  computed: {
    ...mapGetters(['familyTreeLibraryActiveFiltersState']),
  },
  methods: {
    removeActiveFilter({filterType, filterValue}) {
      this.$store.commit('removeFamilyTreeLibraryAssetsFilterState', {filterType, filterValue});
      this.$emit('remove', {filterType, filterValue});
    },
  },
};
</script>

<style lang="scss" scoped>
.active-filters-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;

  .active-filter-tag {
    margin: 10px 7px;
    &:first-child {
      margin-left: 1px;
    }
  }
}
</style>
