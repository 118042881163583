<template>
  <multiselect
    :ref="ref"
    :class="multiselectClasses"
    :value="value"
    :options="options"
    :placeholder="placeholder"
    group-values="values"
    group-label="filterName"
    :group-select="false"
    :internal-search="false"
    :searchable="true"
    track-by="displayName"
    label="displayName"
    :loading="familyTreeLibrarySearchOptionsLoadingState"
    :max-height="multiselectHeight"
    :option-height="optionHeight"
    :clear-on-select="true"
    :show-no-options="false"
    :show-no-results="false"
    @search-change="onChange"
    @select="onSelect"
    @open="onOpen"
    @close="onClose"
  >
    <search-icon slot="clear" class="search-icon" :size="24"></search-icon>
    <span slot="option" slot-scope="props" class="option">
      <map-marker-icon v-if="isLocation(props.option)" :size="20"></map-marker-icon>
      <person-icon v-if="isPerson(props.option)" :size="20"></person-icon>
      <span class="option-label">{{ getOptionLabel(props.option) }}</span>
    </span>
  </multiselect>
</template>

<script>
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import PersonIcon from 'vue-material-design-icons/Account';
import SearchIcon from 'vue-material-design-icons/Magnify';
import MapMarkerIcon from 'vue-material-design-icons/MapMarker';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

import {FILTER_LOCATIONS, FILTER_PERSONS, FILTER_SEARCH} from '../consts';

export default {
  data() {
    const optionHeight = 40;
    return {
      options: [],
      ref: 'multiselect',
      value: [],
      searchValue: '',
      optionHeight: optionHeight,
      multiselectHeight: optionHeight * 13, //  "search for" option, 5 persons, 5 locations and 2 groups
    };
  },
  created() {
    document.addEventListener('keydown', this.autofocus);
  },
  destroyed() {
    document.removeEventListener('keydown', this.autofocus);
  },
  computed: {
    ...mapGetters([
      'familyTreeLibrarySearchOptionsLoadingState',
      'familyTreeLibrarySearchOptionsState',
      'libraryFamilyTreeIdState',
      'familyTreeLibraryActiveFiltersState',
      'familyTreeLibraryFiltersAsStringsState',
    ]),
    multiselectClasses() {
      return {'search-bar-multiselect': true, 'is-searched': this.searchValue};
    },
    placeholder() {
      return 'Search for people, places, etc.';
    },
    searchFilter() {
      return this.searchValue
        ? {
            filterName: '',
            values: [
              {
                displayName: `Search for "${this.searchValue}"`,
                isCustomSearch: true,
                filterType: FILTER_SEARCH,
                filterValue: this.searchValue,
              },
            ],
          }
        : {};
    },
  },
  methods: {
    autofocus(e) {
      if (['INPUT', 'TEXTAREA'].includes(e.target.tagName)) {
        return;
      }
      if ((e.which <= 90 && e.which >= 48) || (e.which >= 96 && e.which <= 105)) {
        // only letters and numbers
        this.$refs[this.ref].$refs['search'].focus();
      }
    },
    onChange(value) {
      this.searchValue = value;
      this.changeOptions([]);
      if (this.$refs[this.ref].isOpen) {
        this.fetchSearchOptionsWithDebounce();
      }
    },
    onSelect(value) {
      const displayName = value.filterType === FILTER_SEARCH ? `"${value.filterValue}"` : value.displayName;
      if (
        !this.familyTreeLibraryActiveFiltersState.find(
          filter => filter.filterValue.toString() === value.filterValue.toString()
        )
      ) {
        this.$store.commit('addToFamilyTreeLibraryAssetsFilterState', {
          filterType: value.filterType,
          filterValue: value.filterValue,
          displayName: displayName,
        });

        this.$emit('select', value);
      }
    },
    getOptionLabel(option) {
      if (isEmpty(option)) return '';
      if (option.$isLabel) return option.$groupLabel;
      return option['displayName'];
    },
    isLocation(option) {
      return option.filterType === FILTER_LOCATIONS;
    },
    isPerson(option) {
      return option.filterType === 'persons';
    },
    onOpen() {
      this.$refs[this.ref].isOpen = false; // hack: hide options and re-open after they are loaded for animation
      this.fetchSearchOptionsWithDebounce();
    },
    onClose() {
      this.$store.commit('setFamilyTreeLibrarySearchOptionsState', null);
      this.changeOptions([]);
    },
    changeOptions(options) {
      this.options = options;
    },
    fetchSearchOptionsWithDebounce: debounce(function () {
      const persons = this.familyTreeLibraryFiltersAsStringsState[FILTER_PERSONS];
      const locations = this.familyTreeLibraryFiltersAsStringsState[FILTER_LOCATIONS];
      this.$store
        .dispatch('getFamilyTreeLibrarySearchOptionsAction', {
          familyTreeId: this.libraryFamilyTreeIdState,
          q: this.searchValue,
          persons,
          locations,
        })
        .then(resp => {
          let locationOptions =
            this.familyTreeLibrarySearchOptionsState.locations &&
            this.familyTreeLibrarySearchOptionsState.locations.map(location => {
              return {displayName: location, filterType: FILTER_LOCATIONS, filterValue: location};
            });
          let personsOptions =
            this.familyTreeLibrarySearchOptionsState.persons &&
            this.familyTreeLibrarySearchOptionsState.persons.map(person => {
              return {displayName: person.full_name, filterType: FILTER_PERSONS, filterValue: person.object_id};
            });
          const options = [
            this.searchFilter,
            {filterName: 'People', values: personsOptions},
            {filterName: 'Places', values: locationOptions},
          ];
          const optionsExist = options.map(option => Boolean(option.values && option.values.length)).includes(true);
          this.changeOptions(optionsExist ? options : []);

          this.$refs[this.ref].isOpen = true;
        });
    }, 500),
  },
  components: {Multiselect, SearchIcon, MapMarkerIcon, PersonIcon},
};
</script>

<style lang="scss" scoped>
.search-bar-multiselect.multiselect {
  &::v-deep .multiselect__tags {
    padding-left: 40px;
    height: 48px;
  }
}

.search-bar-multiselect::v-deep .multiselect__option {
  padding: 0;
}

/* Hide the placeholder div and make input always visible for input to be accessible even though multiselect isOpen=false */
.search-bar-multiselect::v-deep .multiselect__input {
  width: 100% !important;
  position: relative !important;
  padding: 4px 0 0 5px !important;
}
.search-bar-multiselect::v-deep .multiselect__placeholder {
  display: none;
}

.search-bar-multiselect.is-searched::v-deep .multiselect__element:first-child {
  display: none; // hide empty group name for `search for` option
}

.multiselect__option {
  .option {
    display: flex;
    align-items: center;
    height: 42px;
    margin-left: 10px;

    .material-design-icon::v-deep {
      opacity: 0.8;
    }

    .option-label {
      margin-left: 11px;
      line-height: 1;
      position: relative;
      top: 1px;
    }
  }
  &.multiselect__option--group {
    .option-label {
      margin-left: 0;
    }
  }
}

.multiselect::v-deep {
  .multiselect__option--disabled {
    background: #f3f3f3 !important;
    border-bottom: 1px solid #eee;
    color: #aaa !important;
    font-size: 0.7em;
    text-transform: uppercase;
    height: 30px;
    min-height: auto;
    .option {
      height: 30px;
    }
  }
  .multiselect__option--highlight::after {
    top: 2px;
  }
}

.search-icon {
  position: absolute;
  top: 13px;
  left: 12px;
  opacity: 0.5;
}
</style>
