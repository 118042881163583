<template>
  <div :ref="ref" class="mcr-select" v-click-outside="hideOptions" tabindex="-1">
    <div class="value" @click="toggleOptionsVisible">{{ selectedValue }}</div>
    <mcr-select-options
      v-if="optionsVisible"
      :options="options"
      :selected-key="selectedKey"
      @option-click="select"
    ></mcr-select-options>
  </div>
</template>

<script>
import McrSelectOptions from './mcrSelectOptions';

export default {
  components: {McrSelectOptions},
  data() {
    return {
      optionsVisible: false,
      ref: 'mcr-select',
    };
  },
  props: {
    options: Array,
    selectedKey: [String, Number],
    isOptional: {type: Boolean, default: false},
  },
  computed: {
    selectedValue() {
      const option = this.options.find(option => option.key === this.selectedKey);
      return option ? option.value : '';
    },
  },
  methods: {
    select(option) {
      this.$emit('select', option.key);
      this.hideOptions();
      this.$refs[this.ref].blur();
    },
    toggleOptionsVisible() {
      this.optionsVisible = !this.optionsVisible;
    },
    hideOptions() {
      this.optionsVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mcr-select {
  position: relative;
  display: flex;
  &:focus {
    z-index: 100;
  }
  .value {
    border-radius: 4px;
    padding: 9px 12px;
    padding-right: 32px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    transition: all 0.2s ease;

    &:after {
      position: absolute;
      content: '';
      top: calc(50% - 2px);
      right: 12px;
      border: 6px solid $input-symbol-color;
      border-color: $input-symbol-color transparent transparent transparent;
    }
  }

  &.light-select {
    .value {
      background: rgba(0, 0, 0, 0);
      border: 1px solid transparent;
      color: $supplemental-text-color;
      text-align: right;
      padding-right: 20px;
      &:hover {
        color: $text-color;
      }
      &:after {
        right: 0px;
      }
    }
  }
}
</style>
