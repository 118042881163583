<template>
  <new-image-sub-actions
    v-touch:swipe.bottom="closeModal"
    @select-from-library="selectFromLibraryHandler"
    @upload="uploadFromDeviceHandler"
  >
  </new-image-sub-actions>
</template>

<script>
import NewImageSubActions from '@common/pages/imageToText/NewImageSubActions';

export default {
  props: {
    selectFromLibrary: Function,
    uploadFromDevice: Function,
  },
  methods: {
    selectFromLibraryHandler() {
      this.closeModal();
      this.selectFromLibrary();
    },
    uploadFromDeviceHandler() {
      this.closeModal();
      this.uploadFromDevice();
    },
    closeModal() {
      this.$emit('close');
    },
  },
  name: 'NewImageSubActionsModal',
  components: {NewImageSubActions},
};
</script>

<style scoped lang="scss">
.new-image-sub-actions {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &::v-deep {
    .sub-action {
      width: auto;
      border-top: 1px solid $neutral-200;
    }
  }
}
</style>
