<template>
  <input type="file" ref="input" class="files-input" @change="filesSelectedHandler" />
</template>

<script>
import consts from '@common/utils/consts';

export default {
  data() {
    return {
      maxFileSizeMB: consts.maxFileSizeMB,
      maxFileSize: consts.maxFileSizeMB * 1024 * 1024,
      allowedFileExtensions: [...consts.imageExtensions],
    };
  },
  methods: {
    filesSelectedHandler(event) {
      const files = event.target.files;
      this.filesSelected(files);
    },
    filesSelected(files) {
      const file = files[0];
      if (file.size > this.maxFileSize) {
        return this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB`);
      }
      const fileExtension = file.name.split('.').pop();
      if (!this.allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        return this.handleFilesSelectError(`Allowed file extensions: ${this.allowedFileExtensions}`);
      }
      this.$emit('upload-started');
      this.$store
        .dispatch('uploadImageToTextAction', {file})
        .then(response => {
          this.$emit('uploaded');
          this.$router.push({name: 'image-to-text-results', params: {assetId: response.object_id}});
        })
        .catch(error => {
          const message = error && error.response && error.response.data && error.response.data.error;
          this.handleFilesSelectError(message || 'Error. Please try again later.');
        });
      this.clearInputFiles();
    },
    clearInputFiles() {
      this.$refs.input.value = '';
    },
    handleFilesSelectError(errorText) {
      this.clearInputFiles();
      this.$toasted.error(errorText);
    },
  },
  name: 'ImageToTextInput',
};
</script>

<style scoped lang="scss">
.files-input {
  display: none;
}
</style>
